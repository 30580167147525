import FileSaver from 'file-saver';
import { serialize } from 'object-to-formdata';

import { IMagnetometerDatasetCreateModel } from '../domain/models/magnetometerDatasetCreateModel';
import { IMagnetometerDatasetExportModel } from '../domain/models/magnetometerDatasetExportModel';
import { createApiClient } from '../utils/httpClient';
import { getFilename } from '../utils/responseHelper';

import { IMagnetometerDatasetCreateRequest } from './requestModels/magnetometerDatasetCreateRequest';
import { IMagnetometerColorSchemeResponse } from './responseModels/magnetometerColorSchemeResponse';
import { IMagnetometerDatasetResponse } from './responseModels/magnetometerDatasetResponse';

export async function getMagnetometerDatasets(): Promise<IMagnetometerDatasetResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Magnetometer`;

  const response = await apiClient.get<IMagnetometerDatasetResponse[]>(url);

  return response.data;
}

export async function deleteMagnetometerDataset(id: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Magnetometer/${id}`;

  await apiClient.delete(url);
}

export async function createMagnetometerDataset(model: IMagnetometerDatasetCreateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Magnetometer`;

  const request: IMagnetometerDatasetCreateRequest = {
    title: model.title,
    description: model.description,
    color: model.color,
    lineSpacing: model.lineSpacing,
    surveyDate: model.surveyDate,
    projection: model.projection,
    csvFile: model.csvFile.blobFile as File
  };
  const formData = serialize(request, { dotsForObjectNotation: true, noFilesWithArrayNotation: true });

  await apiClient.post(url, formData);
}

export async function exportMagnetometerDataset(model: IMagnetometerDatasetExportModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Magnetometer/${model.id}/Export`;

  const response = await apiClient.get<Blob>(url, {
    responseType: 'blob'
  });

  //TODO ::: File name is getting from context-disposition header, reuse in other places once tested /Renat
  FileSaver.saveAs(response.data, getFilename(response));
}

export async function getMagnetometerColorScheme(): Promise<IMagnetometerColorSchemeResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Magnetometer/ColorScheme`;

  const response = await apiClient.get<IMagnetometerColorSchemeResponse[]>(url);

  return response.data;
}
