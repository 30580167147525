import { all, apply, call, cancelled, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import {
  getAuxiliaryPage,
  getAuxiliaryPages,
  switchAuxiliaryPageEnabled,
  updateAuxiliaryPageContent
} from '../../services/auxiliaryPagesService';
import { createAllObservationsExport, createDhyObservationsExport, createWreckExport } from '../../services/exportService';
import {
  createMagnetometerDataset,
  deleteMagnetometerDataset,
  exportMagnetometerDataset,
  getMagnetometerDatasets
} from '../../services/magnetometerService';
import { createReference, updateReference } from '../../services/referenceService';
import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';
import { IAuxiliaryPageResponse } from '../../services/responseModels/auxiliaryPageResponse';
import { IAuxiliaryPageStatusResponse } from '../../services/responseModels/auxiliaryPageStatusResponse';
import { IMagnetometerDatasetResponse } from '../../services/responseModels/magnetometerDatasetResponse';
import { getAuditLog } from '../../services/utilityService';
import { constants } from '../../utils/constants';
import { adminActions } from '../actions/adminActions';
import { commonActions } from '../actions/commonActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';

function* exportWreckApiCall(action: ReturnType<typeof adminActions.wreckExportStarted>) {
  const abortController = new AbortController();

  try {
    yield put(adminActions.wreckExportStateChanged(true));
    yield call(createWreckExport, action.payload, abortController.signal);
  } catch {
    yield put(notificationActions.push({ type: 'error', body: i18n.t('Failed to export data'), title: i18n.t('Failed') }));
  } finally {
    const wasCancelled: boolean = yield cancelled();

    if (wasCancelled) {
      yield apply(abortController, abortController.abort, []);
      yield put(notificationActions.push({ type: 'warning', title: i18n.t('Export cancelled') }));
    }

    yield put(adminActions.wreckExportStateChanged(false));
  }
}

function* exportAllObservationsApiCall(action: ReturnType<typeof adminActions.allObservationsExportStarted>) {
  const abortController = new AbortController();

  try {
    yield put(adminActions.allObservationsExportStateChanged(true));
    yield call(createAllObservationsExport, action.payload, abortController.signal);
  } catch {
    yield put(notificationActions.push({ type: 'error', body: i18n.t('Failed to export data'), title: i18n.t('Failed') }));
  } finally {
    const wasCancelled: boolean = yield cancelled();

    if (wasCancelled) {
      yield apply(abortController, abortController.abort, []);
      yield put(notificationActions.push({ type: 'warning', title: i18n.t('Export cancelled') }));
    }

    yield put(adminActions.allObservationsExportStateChanged(false));
  }
}

function* exportDhyObservationsApiCall(action: ReturnType<typeof adminActions.allObservationsExportStarted>) {
  const abortController = new AbortController();

  try {
    yield put(adminActions.dhyObservationsExportStateChanged(true));
    yield call(createDhyObservationsExport, action.payload, abortController.signal);
  } catch {
    yield put(notificationActions.push({ type: 'error', body: i18n.t('Failed to export data'), title: i18n.t('Failed') }));
  } finally {
    const wasCancelled: boolean = yield cancelled();

    if (wasCancelled) {
      yield apply(abortController, abortController.abort, []);
      yield put(notificationActions.push({ type: 'warning', title: i18n.t('Export cancelled') }));
    }

    yield put(adminActions.dhyObservationsExportStateChanged(false));
  }
}

function* getAuditLogApiCall(action: ReturnType<typeof adminActions.auditLogRequested>) {
  try {
    yield put(adminActions.auditLogFetchStateChanged(true));
    const auditLog: IAuditLogResponse = yield call(getAuditLog, action.payload);
    yield put(adminActions.auditLogFetched(auditLog));
    yield put(adminActions.auditLogFetchStateChanged(false));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve audit logs') }));
  }
}

function* referenceCreateApiCall(action: ReturnType<typeof adminActions.referenceCreate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(createReference, action.payload.request);
    yield put(commonActions.referencesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to save reference') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* referenceUpdateApiCall(action: ReturnType<typeof adminActions.referenceUpdate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(updateReference, ...[action.payload.id, action.payload.request]);
    yield put(commonActions.referencesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to save reference') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* getAuxiliaryPagesApiCall() {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.auxiliaryPages));
    const auxiliaryPages: IAuxiliaryPageStatusResponse[] = yield call(getAuxiliaryPages);
    yield put(adminActions.auxiliaryPagesFetched(auxiliaryPages));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to retrieve auxiliary pages') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.auxiliaryPages));
  }
}

function* auxiliaryPageEnableSwitchApiCall(action: ReturnType<typeof adminActions.auxiliaryPageEnabledSwitch>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.auxiliaryPages));
    yield call(switchAuxiliaryPageEnabled, action.payload);
    yield put(adminActions.auxiliaryPagesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to update auxiliary page') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.auxiliaryPages));
  }
}

function* auxiliaryPageRequestedApiCall(action: ReturnType<typeof adminActions.auxiliaryPageRequested>) {
  try {
    yield put(adminActions.auxiliaryPageReset());
    const auxiliaryPage: IAuxiliaryPageResponse = yield call(getAuxiliaryPage, action.payload);
    yield put(adminActions.auxiliaryPageFetched(auxiliaryPage));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to retrieve auxiliary page') }));
  }
}

function* auxiliaryPageContentUpdateApiCall(action: ReturnType<typeof adminActions.auxiliaryPageContentUpdate>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.auxiliaryPages));
    yield call(updateAuxiliaryPageContent, action.payload);
    yield put(adminActions.auxiliaryPagesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to update auxiliary page') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.auxiliaryPages));
  }
}

function* getMagnetometerDatasetsApiCall() {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.magnetometerDatasets));
    const magnetometerDatasets: IMagnetometerDatasetResponse[] = yield call(getMagnetometerDatasets);
    yield put(adminActions.magnetometerDatasetsFetched(magnetometerDatasets));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to retrieve magnetometer datasets') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.magnetometerDatasets));
  }
}

function* deleteMagnetometerDatasetApiCall(action: ReturnType<typeof adminActions.magnetometerDatasetDelete>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.magnetometerDatasets));
    yield call(deleteMagnetometerDataset, action.payload);
    yield put(adminActions.magnetometerDatasetDeleted(action.payload));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to delete magnetometer dataset') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.magnetometerDatasets));
  }
}

function* createMagnetometerDatasetApiCall(action: ReturnType<typeof adminActions.magnetometerDatasetCreate>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.magnetometerDatasets));
    yield call(createMagnetometerDataset, action.payload);
    yield put(adminActions.magnetometerDatasetsRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to create magnetometer dataset') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.magnetometerDatasets));
  }
}

function* exportMagnetometerDatasetApiCall(action: ReturnType<typeof adminActions.magnetometerDatasetExport>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(exportMagnetometerDataset, action.payload);
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to export magnetometer dataset') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* tryExportWrecks(action: ReturnType<typeof adminActions.wreckExportStarted>) {
  yield race([call(exportWreckApiCall, action), take(adminActions.wreckExportCancelled)]);
}

function* tryExportAllObservations(action: ReturnType<typeof adminActions.allObservationsExportStarted>) {
  yield race([call(exportAllObservationsApiCall, action), take(adminActions.allObservationsExportCancelled)]);
}

function* tryExportDhyObservations(action: ReturnType<typeof adminActions.dhyObservationsExportStarted>) {
  yield race([call(exportDhyObservationsApiCall, action), take(adminActions.dhyObservationsExportCancelled)]);
}

function* wreckExportStartedHandler() {
  yield takeLatest(adminActions.wreckExportStarted, tryExportWrecks);
}

function* observationExportStartedHandler() {
  yield takeLatest(adminActions.allObservationsExportStarted, tryExportAllObservations);
}

function* dhyObservationExportStartedHandler() {
  yield takeLatest(adminActions.dhyObservationsExportStarted, tryExportDhyObservations);
}

function* getAuditLogHandler() {
  yield takeLatest(adminActions.auditLogRequested, getAuditLogApiCall);
}

function* referenceCreateHandler() {
  yield takeLatest(adminActions.referenceCreate, referenceCreateApiCall);
}

function* referenceUpdateHandler() {
  yield takeEvery(adminActions.referenceUpdate, referenceUpdateApiCall);
}

function* auxiliaryPagesRequestedHandler() {
  yield takeLatest(adminActions.auxiliaryPagesRequested, getAuxiliaryPagesApiCall);
}

function* auxiliaryPageEnableSwitchHandler() {
  yield takeLatest(adminActions.auxiliaryPageEnabledSwitch, auxiliaryPageEnableSwitchApiCall);
}

function* auxiliaryPageRequestedHandler() {
  yield takeLatest(adminActions.auxiliaryPageRequested, auxiliaryPageRequestedApiCall);
}

function* auxiliaryPageContentUpdateHandler() {
  yield takeEvery(adminActions.auxiliaryPageContentUpdate, auxiliaryPageContentUpdateApiCall);
}

function* magnetometerDatasetsRequestedHandler() {
  yield takeLatest(adminActions.magnetometerDatasetsRequested, getMagnetometerDatasetsApiCall);
}

function* magnetometerDatasetDeleteHandler() {
  yield takeEvery(adminActions.magnetometerDatasetDelete, deleteMagnetometerDatasetApiCall);
}

function* magnetometerDatasetCreateHandler() {
  yield takeEvery(adminActions.magnetometerDatasetCreate, createMagnetometerDatasetApiCall);
}

function* magnetometerDatasetExportHandler() {
  yield takeEvery(adminActions.magnetometerDatasetExport, exportMagnetometerDatasetApiCall);
}

export function* adminSaga(): Generator {
  yield all([
    wreckExportStartedHandler(),
    observationExportStartedHandler(),
    dhyObservationExportStartedHandler(),
    getAuditLogHandler(),
    referenceCreateHandler(),
    referenceUpdateHandler(),
    auxiliaryPagesRequestedHandler(),
    auxiliaryPageEnableSwitchHandler(),
    auxiliaryPageRequestedHandler(),
    auxiliaryPageContentUpdateHandler(),
    magnetometerDatasetsRequestedHandler(),
    magnetometerDatasetDeleteHandler(),
    magnetometerDatasetCreateHandler(),
    magnetometerDatasetExportHandler()
  ]);
}
