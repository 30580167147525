import { OidcSecure } from '@axa-fr/react-oidc';
import loadable from '@loadable/component';
import { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import { urlHelper } from '../utils/urlHelper';

import LoaderComponent from './core/loaderComponent';

const options = { fallback: <LoaderComponent zIndex={100} /> };

const ContactsOverviewContainer = loadable(
  () => import('../containers/contactsOverviewContainer' /* webpackChunkName: 'ContactsOverviewContainer' */),
  options
);

const ContactDetailsContainer = loadable(
  () => import('../containers/contactDetailsContainer' /* webpackChunkName: 'ContactDetailsContainer' */),
  options
);

const AuxiliaryInfoPagesContainer = loadable(
  () => import('../containers/auxiliaryInfoPagesContainer' /* webpackChunkName: 'AuxiliaryInfoPagesContainer' */),
  options
);

const InfoComponent = loadable(() => import('./infoComponent' /* webpackChunkName: 'InfoComponent' */), options);

const SignedOutComponent = loadable(
  () => import('./authentication/signedOutComponent' /* webpackChunkName: 'SignedOutComponent' */),
  options
);

const NotFoundComponent = loadable(() => import('./notFoundComponent' /* webpackChunkName: 'NotFoundComponent' */), options);

const NotAuthorizedComponent = loadable(
  () => import('./authorization/notAuthorizedComponent' /* webpackChunkName: 'NotAuthorizedComponent' */),
  options
);

const LandingPageComponent = loadable(() => import('./landingPageComponent' /* webpackChunkName: 'LandingPageComponent' */), options);

const AdminContainer = loadable(() => import('../containers/adminContainer' /* webpackChunkName: 'AdminContainer' */), options);

const DashboardContainer = loadable(() => import('../containers/dashboardContainer' /* webpackChunkName: 'DashboardContainer' */), options);

const buildRoute = (path: string, element: ReactNode, authRequired = true) => {
  if (authRequired) {
    element = <OidcSecure>{element}</OidcSecure>;
  }

  return <Route path={path} element={element} />;
};

const RoutingComponent: FC = () => {
  return (
    <Routes>
      {buildRoute(urlHelper.patterns.root, <LandingPageComponent />, false)}
      {buildRoute(urlHelper.patterns.contacts, <ContactsOverviewContainer />)}
      {buildRoute(urlHelper.patterns.details, <ContactDetailsContainer />)}
      {buildRoute(urlHelper.patterns.admin, <AdminContainer />)}
      {buildRoute(urlHelper.patterns.dashboard, <DashboardContainer />)}
      {buildRoute(urlHelper.patterns.auxiliaryInfo, <AuxiliaryInfoPagesContainer />, false)}
      {buildRoute(urlHelper.patterns.info, <InfoComponent />, false)}
      {buildRoute(urlHelper.patterns.signedOut, <SignedOutComponent />, false)}
      {buildRoute(urlHelper.patterns.notAuthorized, <NotAuthorizedComponent />, false)}
      {buildRoute(urlHelper.patterns.wildcard, <NotFoundComponent />, false)}
    </Routes>
  );
};

export default RoutingComponent;
