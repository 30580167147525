import { createAction } from '@reduxjs/toolkit';

import { IDashboardAreaDetailsModel } from '../../domain/models/dashboardDetailsRequestModel';
import { IDashboardDetailsRequest } from '../../services/requestModels/dashboardDetailsRequest';
import { IDashboardActivityResponse } from '../../services/responseModels/dashboardActivityResponse';
import { IDashboardDetailsResponse } from '../../services/responseModels/dashboardDetailsResponse';
import { IDashboardDistributionResponse } from '../../services/responseModels/dashboardDistributionResponse';
import { IDashboardSummaryResponse } from '../../services/responseModels/dashboardSummaryResponse';

const dashboardStateCleanup = createAction('DASHBOARD_STATE_CLEANUP');

const dashboardSummaryRequested = createAction('DASHBOARD_SUMMARY_REQUESTED');
const dashboardSummaryFetched = createAction<IDashboardSummaryResponse>('DASHBOARD_SUMMARY_FETCHED');

const dashboardDistributionRequested = createAction('DASHBOARD_DISTRIBUTION_REQUESTED');
const dashboardDistributionFetched = createAction<IDashboardDistributionResponse>('DASHBOARD_DISTRIBUTION_FETCHED');

const dashboardActivityRequested = createAction('DASHBOARD_ACTIVITY_REQUESTED');
const dashboardActivityFetched = createAction<IDashboardActivityResponse>('DASHBOARD_ACTIVITY_FETCHED');

const dashboardDetailsRequested = createAction<IDashboardAreaDetailsModel<IDashboardDetailsRequest> | undefined>(
  'DASHBOARD_DETAILS_REQUESTED'
);
const dashboardDetailsFetched = createAction<IDashboardAreaDetailsModel<IDashboardDetailsResponse> | undefined>(
  'DASHBOARD_DETAILS_FETCHED'
);

export const dashboardActions = {
  dashboardStateCleanup,
  dashboardSummaryRequested,
  dashboardSummaryFetched,
  dashboardDistributionRequested,
  dashboardDistributionFetched,
  dashboardActivityRequested,
  dashboardActivityFetched,
  dashboardDetailsRequested,
  dashboardDetailsFetched
};
