import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../store/appReducer';
import { LoadingContext, LoadingState } from '../utils/loadingContext';

const LoadingContextProvider: FC<ILoadingContextProviderProps> = (props: ILoadingContextProviderProps) => {
  const loadingState = useSelector<AppState, LoadingState>(s => s.loadingState);

  return <LoadingContext.Provider value={loadingState}>{props.children}</LoadingContext.Provider>;
};

export default LoadingContextProvider;

export interface ILoadingContextProviderProps {
  children: ReactElement;
}
