import { all, call, put, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { getAreas } from '../../services/areasService';
import { createFilter, deleteFilter, getFilters, updateFilter } from '../../services/filterService';
import { getMagnetometerColorScheme } from '../../services/magnetometerService';
import { IAreaResponse } from '../../services/responseModels/areaResponse';
import { IFiltersResponse } from '../../services/responseModels/filterResponse';
import { IMagnetometerColorSchemeResponse } from '../../services/responseModels/magnetometerColorSchemeResponse';
import { contactsOverviewActions } from '../actions/contactsOverviewActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';

function* getAreasApiCall() {
  try {
    const areas: IAreaResponse[] = yield call(getAreas);
    yield put(contactsOverviewActions.areasFetched(areas));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve areas') }));
  }
}

function* filtersFetchApiCall(action: ReturnType<typeof contactsOverviewActions.filtersFetch>) {
  try {
    const filters: IFiltersResponse[] = yield call(getFilters, action.payload);
    yield put(contactsOverviewActions.filtersFetched(filters));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve filters') }));
  }
}

function* filterCreateApiCall(action: ReturnType<typeof contactsOverviewActions.filterCreate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    const filterId: number = yield call(createFilter, action.payload);
    yield put(contactsOverviewActions.filterSaved({ ...action.payload.request, id: filterId }));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save filter') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* filterUpdateApiCall(action: ReturnType<typeof contactsOverviewActions.filterUpdate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(updateFilter, action.payload);
    yield put(contactsOverviewActions.filterSaved({ ...action.payload.request, id: action.payload.id }));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save filter') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* filterDeleteApiCall(action: ReturnType<typeof contactsOverviewActions.filterDelete>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(deleteFilter, action.payload);
    yield put(contactsOverviewActions.filterDeleted(action.payload.id));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete filter') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* getMagnetometerColorSchemeApiCall() {
  try {
    const colors: IMagnetometerColorSchemeResponse[] = yield call(getMagnetometerColorScheme);
    yield put(contactsOverviewActions.magnetometerColorSchemeFetched(colors));
  } catch {
    yield put(
      notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve magnetometer color scheme') })
    );
  }
}

function* getAreasHandler() {
  yield takeLatest(contactsOverviewActions.areasRequested, getAreasApiCall);
}

function* filtersFetchHandler() {
  yield takeLatest(contactsOverviewActions.filtersFetch, filtersFetchApiCall);
}

function* filterCreateHandler() {
  yield takeLatest(contactsOverviewActions.filterCreate, filterCreateApiCall);
}

function* filterUpdateHandler() {
  yield takeLatest(contactsOverviewActions.filterUpdate, filterUpdateApiCall);
}

function* filterDeleteHandler() {
  yield takeLatest(contactsOverviewActions.filterDelete, filterDeleteApiCall);
}

function* magnetometerColorSchemeRequestedHandler() {
  yield takeLatest(contactsOverviewActions.magnetometerColorSchemeRequested, getMagnetometerColorSchemeApiCall);
}

export function* contactsOverviewSaga(): Generator {
  yield all([
    getAreasHandler(),
    filtersFetchHandler(),
    filterCreateHandler(),
    filterUpdateHandler(),
    filterDeleteHandler(),
    magnetometerColorSchemeRequestedHandler()
  ]);
}
