export enum AuxiliaryInfoPage {
  Landing = 'landing',
  About = 'about',
  Contact = 'contact',
  Help = 'help',
  Disclaimer = 'disclaimer',
  Privacy = 'privacy',
  Cookies = 'cookies',
  Downloads = 'downloads'
}
