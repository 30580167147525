import { createReducer } from '@reduxjs/toolkit';

import { IAreaResponse } from '../../services/responseModels/areaResponse';
import { IFiltersResponse } from '../../services/responseModels/filterResponse';
import { IMagnetometerColorSchemeResponse } from '../../services/responseModels/magnetometerColorSchemeResponse';
import { contactsOverviewActions } from '../actions/contactsOverviewActions';

export interface IContactsOverviewState {
  readonly areas: IAreaResponse[];
  readonly filters?: IFiltersResponse[];
  readonly magnetometerColorScheme?: IMagnetometerColorSchemeResponse[];
}

const initialState: IContactsOverviewState = {
  areas: [],
  filters: undefined,
  magnetometerColorScheme: undefined
};

export const contactsOverviewReducer = createReducer(initialState, builder => {
  builder.addCase(contactsOverviewActions.areasFetched, (state, action) => {
    state.areas = action.payload;
  });
  builder.addCase(contactsOverviewActions.filtersFetched, (state, action) => {
    state.filters = action.payload;
  });
  builder.addCase(contactsOverviewActions.filterSaved, (state, action) => {
    if (state.filters) {
      if (!state.filters.some(f => f.id === action.payload.id)) {
        state.filters.push(action.payload);
      } else {
        state.filters = state.filters.map(f => (f.id === action.payload.id ? action.payload : f));
      }
    }
  });
  builder.addCase(contactsOverviewActions.filterDeleted, (state, action) => {
    if (state.filters) {
      state.filters = state.filters.filter(f => f.id !== action.payload);
    }
  });
  builder.addCase(contactsOverviewActions.magnetometerColorSchemeFetched, (state, action) => {
    state.magnetometerColorScheme = action.payload;
  });
});
