import { createApiClient } from '../utils/httpClient';

import { IDashboardDetailsRequest } from './requestModels/dashboardDetailsRequest';
import { IDashboardActivityResponse } from './responseModels/dashboardActivityResponse';
import { IDashboardDetailsResponse } from './responseModels/dashboardDetailsResponse';
import { IDashboardDistributionResponse } from './responseModels/dashboardDistributionResponse';
import { IDashboardSummaryResponse } from './responseModels/dashboardSummaryResponse';

export async function getDashboardSummary(): Promise<IDashboardSummaryResponse> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IDashboardSummaryResponse>('api/Dashboard/Summary');

  return response.data;
}

export async function getDashboardDistribution(): Promise<IDashboardDistributionResponse> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IDashboardDistributionResponse>('api/Dashboard/Distribution');

  return response.data;
}

export async function getDashboardActivity(): Promise<IDashboardActivityResponse> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IDashboardActivityResponse>('api/Dashboard/Activity');

  return response.data;
}

export async function getDashboardDetails(request: IDashboardDetailsRequest): Promise<IDashboardDetailsResponse> {
  const apiClient = createApiClient();

  const response = await apiClient.post<IDashboardDetailsResponse, IDashboardDetailsRequest>('api/Dashboard/Details', request);

  return response.data;
}
