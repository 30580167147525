import { all, call, put, takeEvery } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { getDashboardActivity, getDashboardDetails, getDashboardDistribution, getDashboardSummary } from '../../services/dashboardService';
import { IDashboardActivityResponse } from '../../services/responseModels/dashboardActivityResponse';
import { IDashboardDetailsResponse } from '../../services/responseModels/dashboardDetailsResponse';
import { IDashboardDistributionResponse } from '../../services/responseModels/dashboardDistributionResponse';
import { IDashboardSummaryResponse } from '../../services/responseModels/dashboardSummaryResponse';
import { constants } from '../../utils/constants';
import { dashboardActions } from '../actions/dashboardActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';

function* getDashboardSummaryApiCall() {
  try {
    const summary: IDashboardSummaryResponse = yield call(getDashboardSummary);
    yield put(dashboardActions.dashboardSummaryFetched(summary));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to get dashboard summary') }));
  }
}

function* getDashboardDistributionApiCall() {
  try {
    const distribution: IDashboardDistributionResponse = yield call(getDashboardDistribution);
    yield put(dashboardActions.dashboardDistributionFetched(distribution));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to get dashboard distribution') }));
  }
}

function* getDashboardActivityApiCall() {
  try {
    const activity: IDashboardActivityResponse = yield call(getDashboardActivity);
    yield put(dashboardActions.dashboardActivityFetched(activity));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to get dashboard activity') }));
  }
}

function* getDashboardDetailsApiCall(action: ReturnType<typeof dashboardActions.dashboardDetailsRequested>) {
  try {
    yield put(dashboardActions.dashboardDetailsFetched(undefined));

    if (action.payload) {
      yield put(loadingActions.enableLoading(constants.loadingKeys.dashboardDetails));
      const details: IDashboardDetailsResponse = yield call(getDashboardDetails, action.payload.payload);
      yield put(dashboardActions.dashboardDetailsFetched({ area: action.payload.area, payload: details }));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to get dashboard details') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.dashboardDetails));
  }
}

function* getDashboardSummaryHandler() {
  yield takeEvery(dashboardActions.dashboardSummaryRequested, getDashboardSummaryApiCall);
}

function* getDashboardDistributionHandler() {
  yield takeEvery(dashboardActions.dashboardDistributionRequested, getDashboardDistributionApiCall);
}

function* getDashboardActivityHandler() {
  yield takeEvery(dashboardActions.dashboardActivityRequested, getDashboardActivityApiCall);
}

function* getDashboardDetailsHandler() {
  yield takeEvery(dashboardActions.dashboardDetailsRequested, getDashboardDetailsApiCall);
}

export function* dashboardSaga(): Generator {
  yield all([getDashboardSummaryHandler(), getDashboardDistributionHandler(), getDashboardActivityHandler(), getDashboardDetailsHandler()]);
}
