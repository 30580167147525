export const constants = {
  dateFormat: 'dd-MM-yyyy',
  localizationKey: 'sonarreg-language',
  lastSyncedUserKey: 'sonarreg-last-synced-user',
  lastTrackedUserKey: 'sonarreg-last-tracked-user',

  loadingKeys: {
    downloadFiles: 'downloadFiles',
    auxiliaryPages: 'auxiliaryPages',
    dashboardDetails: 'dashboardDetails',
    magnetometerDatasets: 'magnetometerDatasets'
  }
};
