import { createReducer } from '@reduxjs/toolkit';

import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';
import { IAuxiliaryPageResponse } from '../../services/responseModels/auxiliaryPageResponse';
import { IAuxiliaryPageStatusResponse } from '../../services/responseModels/auxiliaryPageStatusResponse';
import { IMagnetometerDatasetResponse } from '../../services/responseModels/magnetometerDatasetResponse';
import { adminActions } from '../actions/adminActions';

const initialAdminState: IAdminState = {
  wreckExportInProgress: false,
  observationExportInProgress: false,
  dhyObservationExportInProgress: false,
  auditLog: undefined,
  auditLogFetchInProgress: false,
  auxiliaryPages: [],
  activeAuxiliaryPage: undefined,
  magnetometerDatasets: undefined
};

export interface IAdminState {
  readonly wreckExportInProgress: boolean;
  readonly observationExportInProgress: boolean;
  readonly dhyObservationExportInProgress: boolean;
  readonly auditLog?: IAuditLogResponse;
  readonly auditLogFetchInProgress: boolean;
  readonly auxiliaryPages: IAuxiliaryPageStatusResponse[];
  readonly activeAuxiliaryPage?: IAuxiliaryPageResponse;
  readonly magnetometerDatasets?: IMagnetometerDatasetResponse[];
}

export const adminReducer = createReducer(initialAdminState, builder => {
  builder.addCase(adminActions.wreckExportStateChanged, (state, action) => {
    state.wreckExportInProgress = action.payload;
  });
  builder.addCase(adminActions.allObservationsExportStateChanged, (state, action) => {
    state.observationExportInProgress = action.payload;
  });
  builder.addCase(adminActions.dhyObservationsExportStateChanged, (state, action) => {
    state.dhyObservationExportInProgress = action.payload;
  });
  builder.addCase(adminActions.auditLogFetched, (state, action) => {
    state.auditLog = action.payload;
  });
  builder.addCase(adminActions.auditLogFetchStateChanged, (state, action) => {
    state.auditLogFetchInProgress = action.payload;
  });
  builder.addCase(adminActions.auxiliaryPagesFetched, (state, action) => {
    state.auxiliaryPages = action.payload;
  });
  builder.addCase(adminActions.auxiliaryPageFetched, (state, action) => {
    state.activeAuxiliaryPage = action.payload;
  });
  builder.addCase(adminActions.auxiliaryPageReset, state => {
    state.activeAuxiliaryPage = undefined;
  });
  builder.addCase(adminActions.magnetometerDatasetsFetched, (state, action) => {
    state.magnetometerDatasets = action.payload;
  });
  builder.addCase(adminActions.magnetometerDatasetDeleted, (state, action) => {
    state.magnetometerDatasets = state.magnetometerDatasets?.filter(x => x.id !== action.payload);
  });
  builder.addCase(adminActions.adminStateCleanup, () => {
    return initialAdminState;
  });
});
