import { css } from '@emotion/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Footer } from 'rsuite';

import { AuxiliaryInfoPage } from '../domain/enums/auxiliaryInfoPage';
import { environment } from '../utils/environment';
import { urlHelper } from '../utils/urlHelper';

const footerLinksWrapper = css({
  position: 'absolute',
  right: '30px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center'
});

const footerLinksStyle = css({
  fontStyle: 'italic'
});

const footerLinksRightStyle = css([footerLinksStyle], {
  color: 'black',
  fontSize: '11px'
});

const footerStyle = css({
  height: '30px',
  borderTop: '1px solid black',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  zIndex: 8
});

const footerLogo = css({
  position: 'absolute',
  width: '37px',
  height: '20px',
  backgroundColor: '#154273',
  bottom: 0
});

const footerAboutWrapper = css({
  display: 'flex',
  left: '60px',
  alignItems: 'center'
});

const versionNumberLocation = css({
  display: 'flex',
  alignItems: 'center'
});

const versionNumber = css({
  fontSize: '10px',
  color: 'black',
  backgroundColor: '#d6d6d6',
  padding: '2px 5px',
  borderRadius: '10px',
  bottom: 0
});

const versionAndAboutWrapper = css({
  position: 'absolute',
  display: 'flex',
  left: '6px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px'
});

const FooterComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <Footer css={footerStyle}>
      <div css={versionAndAboutWrapper}>
        <div css={versionNumberLocation}>
          <span css={versionNumber}>
            {t('v')}
            {environment.versionNumber}
          </span>
        </div>
        <div css={footerAboutWrapper}>
          <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.About)} css={footerLinksStyle}>
            {t('About this site')}
          </Link>
        </div>
      </div>
      <div css={footerLogo} />
      <div css={footerLinksWrapper}>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Downloads)} css={footerLinksRightStyle}>
          {t('Downloads')}
        </Link>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Contact)} css={footerLinksRightStyle}>
          {t('Contact')}
        </Link>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Help)} css={footerLinksRightStyle}>
          {t('Help')}
        </Link>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Disclaimer)} css={footerLinksRightStyle}>
          {t('Disclaimer')}
        </Link>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Privacy)} css={footerLinksRightStyle}>
          {t('Privacy statement')}
        </Link>
        <Link to={urlHelper.routes.auxiliaryInfo(AuxiliaryInfoPage.Cookies)} css={footerLinksRightStyle}>
          {t('Cookies')}
        </Link>
      </div>
    </Footer>
  );
};

export default FooterComponent;
