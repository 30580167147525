import { load } from '@arcgis/core/geometry/projection';
import React from 'react';
import { all, apply, call, cancelled, put, race, take, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import RwsWizardNotificationComponent, {
  IRwsWizardNotificationComponentProps
} from '../../components/rwsWizardComponent/utils/rwsWizardNotificationComponent';
import { IUserTrackModel } from '../../domain/models/userTrackModel';
import {
  createDownloadFile,
  deleteDownloadFile,
  deleteDownloadFiles,
  getDownloadFiles,
  patchDownloadFile,
  updateDownloadFile
} from '../../services/downloadFileService';
import { createGisExport, createWordExport } from '../../services/exportService';
import { getContactsInRadius, switchContactArchival, switchContactDeletion } from '../../services/nationalContactService';
import { getReferences } from '../../services/referenceService';
import { IGisExportRequest } from '../../services/requestModels/gisExportRequest';
import { IWordExportRequest } from '../../services/requestModels/wordExportRequest';
import { IDownloadFileResponse } from '../../services/responseModels/downloadFileResponse';
import { INationalContactInRadiusResponse } from '../../services/responseModels/nationalContactInRadiusResponse';
import { IReferenceResponse } from '../../services/responseModels/referenceResponse';
import { ITokenResponse } from '../../services/responseModels/tokenResponse';
import { completeWizard } from '../../services/rwsObservationService';
import { syncUser, trackUser } from '../../services/userService';
import { getToken } from '../../services/utilityService';
import { constants } from '../../utils/constants';
import { environment } from '../../utils/environment';
import { commonActions } from '../actions/commonActions';
import { contactDetailsActions } from '../actions/contactDetailsActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';

function* loadProjections() {
  yield call(load);
  yield put(commonActions.projectionsLoaded());
}

//TODO ::: Propose a generic way to cancel requests /Renat
function* tryCallExportApi<T>(payload: T, apiCall: (model: T, signal: AbortSignal) => Promise<void>) {
  const abortController = new AbortController();

  try {
    yield put(commonActions.exportStateChanged(true));
    yield call(apiCall, payload, abortController.signal);
  } catch {
    yield put(notificationActions.push({ type: 'error', body: i18n.t('Failed to export data'), title: i18n.t('Failed') }));
  } finally {
    const wasCancelled: boolean = yield cancelled();

    if (wasCancelled) {
      yield apply(abortController, abortController.abort, []);
      yield put(notificationActions.push({ type: 'warning', title: i18n.t('Export cancelled') }));
    }

    yield put(commonActions.exportStateChanged(false));
  }
}

function* tryExportGis(action: ReturnType<typeof commonActions.gisExportStarted>) {
  yield race([call(tryCallExportApi<IGisExportRequest>, action.payload, createGisExport), take(commonActions.activeExportCancelled)]);
}

function* tryExportWord(action: ReturnType<typeof commonActions.wordExportStarted>) {
  yield race([call(tryCallExportApi<IWordExportRequest>, action.payload, createWordExport), take(commonActions.activeExportCancelled)]);
}

function* tokenRequestedApiCall(action: ReturnType<typeof commonActions.tokenRequested>) {
  try {
    /*
     * That dynamic import is a dirty fix for saga tests to run, if imported normally it throws an exception similar to
     * https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/svelte-kit-build-error/td-p/1123928
     * yield of dynamic import results in any which is disabled with TS comment
     * TS comments are prohibited which is disabled with ESLint comment
     * Should be reverted to normal import once that issue is fixed /Renat
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const identityManager = (yield import('@arcgis/core/identity/IdentityManager.js')).default;

    const tokenResponse: ITokenResponse = yield call(getToken, action.payload);

    const tokenProps: __esri.IdentityManagerRegisterTokenProperties = {
      server: environment.arcGisRestEndpoint,
      token: tokenResponse.token,
      expires: tokenResponse.expires,
      ssl: tokenResponse.ssl
    };

    yield apply(identityManager, identityManager.registerToken, [tokenProps]);
    yield put(commonActions.tokenRetrieved(tokenResponse));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve token') }));
  }
}

function* getContactsInRadiusApiCall(action: ReturnType<typeof commonActions.contactsInRadiusRequested>) {
  try {
    const contactsInRadiusResponse: INationalContactInRadiusResponse[] = yield call(getContactsInRadius, action.payload);
    yield put(commonActions.contactsInRadiusRetrieved(contactsInRadiusResponse));
  } catch {
    yield put(
      notificationActions.push({
        type: 'error',
        title: i18n.t('Failed'),
        body: i18n.t('Failed to retrieve national contacts in the radius')
      })
    );
  }
}

function* completeRwsWizardApiCall(action: ReturnType<typeof commonActions.completeRwsWizard>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    const ncn: number = yield call(completeWizard, action.payload);
    const component: React.ReactNode = yield call(
      React.createElement<IRwsWizardNotificationComponentProps>,
      RwsWizardNotificationComponent,
      {
        ncn: ncn
      }
    );

    yield put(notificationActions.push({ type: 'success', title: i18n.t('Observation created'), body: component, duration: 10000 }));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to create an observation') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* getReferencesApiCall() {
  try {
    const references: IReferenceResponse[] = yield call(getReferences);
    yield put(commonActions.referencesFetched(references));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve references') }));
  }
}

function* archiveNationalContactApiCall(action: ReturnType<typeof commonActions.archiveNationalContacts>) {
  try {
    yield call(switchContactArchival, action.payload);
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to archive the national contact') }));
  } finally {
    if (action.payload.length === 1) {
      yield put(contactDetailsActions.switchNationalContactArchivalState());
    }
  }
}

function* deleteNationalContactApiCall(action: ReturnType<typeof commonActions.deleteNationalContacts>) {
  try {
    yield call(switchContactDeletion, action.payload);
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete the national contact') }));
  } finally {
    if (action.payload.length === 1) {
      yield put(contactDetailsActions.switchNationalContactDeletionState());
    }
  }
}

function* syncUserApiCall(action: ReturnType<typeof commonActions.syncUser>) {
  try {
    yield call(syncUser, action.payload);
    yield call([localStorage, localStorage.setItem], constants.lastSyncedUserKey, JSON.stringify(action.payload));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to sync user details') }));
  }
}

function* trackUserApiCall(action: ReturnType<typeof commonActions.trackUser>) {
  try {
    yield call(trackUser, action.payload);

    const trackedUser: IUserTrackModel = {
      userId: action.payload,
      date: new Date()
    };

    yield call([localStorage, localStorage.setItem], constants.lastTrackedUserKey, JSON.stringify(trackedUser));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to track user') }));
  }
}

function* downloadFilesRequestedApiCall() {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield put(commonActions.downloadFilesReset());
    const downloadFiles: IDownloadFileResponse[] = yield call(getDownloadFiles);
    yield put(commonActions.downloadFilesFetched(downloadFiles));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve downloads') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* downloadFileCreateApiCall(action: ReturnType<typeof commonActions.downloadFileCreate>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield call(createDownloadFile, action.payload);
    yield put(commonActions.downloadFilesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save download information') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* downloadFilePatchApiCall(action: ReturnType<typeof commonActions.downloadFilePatch>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield call(patchDownloadFile, action.payload);
    yield put(commonActions.downloadFilesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save download information') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* downloadFileUpdateApiCall(action: ReturnType<typeof commonActions.downloadFileUpdate>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield call(updateDownloadFile, action.payload);
    yield put(commonActions.downloadFilesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save download information') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* downloadFileDeleteApiCall(action: ReturnType<typeof commonActions.downloadFileDelete>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield call(deleteDownloadFile, action.payload);
    yield put(commonActions.downloadFilesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete download information') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* downloadFilesDeleteApiCall(action: ReturnType<typeof commonActions.downloadFilesDelete>) {
  try {
    yield put(loadingActions.enableLoading(constants.loadingKeys.downloadFiles));
    yield call(deleteDownloadFiles, action.payload);
    yield put(commonActions.downloadFilesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete download information') }));
  } finally {
    yield put(loadingActions.disableLoading(constants.loadingKeys.downloadFiles));
  }
}

function* nationalContactArchivalHandler() {
  yield takeLatest(commonActions.archiveNationalContacts, archiveNationalContactApiCall);
}

function* nationalContactDeletionHandler() {
  yield takeLatest(commonActions.deleteNationalContacts, deleteNationalContactApiCall);
}

function* loadAllProjectionsHandler() {
  yield takeLatest(commonActions.requestProjections, loadProjections);
}

function* gisExportHandler() {
  yield takeLatest(commonActions.gisExportStarted, tryExportGis);
}

function* wordExportHandler() {
  yield takeLatest(commonActions.wordExportStarted, tryExportWord);
}

function* tokenRequestedHandler() {
  yield takeLatest(commonActions.tokenRequested, tokenRequestedApiCall);
}

function* contactsInRadiusHandler() {
  yield takeLatest(commonActions.contactsInRadiusRequested, getContactsInRadiusApiCall);
}

function* completeRwsWizardHandler() {
  yield takeLatest(commonActions.completeRwsWizard, completeRwsWizardApiCall);
}

function* getReferencesHandler() {
  yield takeLatest(commonActions.referencesRequested, getReferencesApiCall);
}

function* syncUserHandler() {
  yield takeLatest(commonActions.syncUser, syncUserApiCall);
}

function* trackUserHandler() {
  yield takeLatest(commonActions.trackUser, trackUserApiCall);
}

function* downloadFilesRequestedHandler() {
  yield takeLatest(commonActions.downloadFilesRequested, downloadFilesRequestedApiCall);
}

function* downloadFileCreateHandler() {
  yield takeLatest(commonActions.downloadFileCreate, downloadFileCreateApiCall);
}

function* downloadFilePatchHandler() {
  yield takeLatest(commonActions.downloadFilePatch, downloadFilePatchApiCall);
}

function* downloadFileUpdateHandler() {
  yield takeLatest(commonActions.downloadFileUpdate, downloadFileUpdateApiCall);
}

function* downloadFileDeleteHandler() {
  yield takeLatest(commonActions.downloadFileDelete, downloadFileDeleteApiCall);
}

function* downloadFilesDeleteHandler() {
  yield takeLatest(commonActions.downloadFilesDelete, downloadFilesDeleteApiCall);
}

export function* commonSaga(): Generator {
  yield all([
    nationalContactArchivalHandler(),
    nationalContactDeletionHandler(),
    loadAllProjectionsHandler(),
    gisExportHandler(),
    wordExportHandler(),
    tokenRequestedHandler(),
    contactsInRadiusHandler(),
    completeRwsWizardHandler(),
    getReferencesHandler(),
    syncUserHandler(),
    trackUserHandler(),
    downloadFilesRequestedHandler(),
    downloadFileCreateHandler(),
    downloadFilePatchHandler(),
    downloadFileUpdateHandler(),
    downloadFileDeleteHandler(),
    downloadFilesDeleteHandler()
  ]);
}
