import { createReducer } from '@reduxjs/toolkit';

import { IDashboardAreaDetailsModel } from '../../domain/models/dashboardDetailsRequestModel';
import { IDashboardActivityResponse } from '../../services/responseModels/dashboardActivityResponse';
import { IDashboardDetailsResponse } from '../../services/responseModels/dashboardDetailsResponse';
import { IDashboardDistributionResponse } from '../../services/responseModels/dashboardDistributionResponse';
import { IDashboardSummaryResponse } from '../../services/responseModels/dashboardSummaryResponse';
import { dashboardActions } from '../actions/dashboardActions';

const initialCommonState: IDashboardState = {
  summary: undefined,
  distribution: undefined,
  activity: undefined,
  details: undefined
};

export interface IDashboardState {
  readonly summary?: IDashboardSummaryResponse;
  readonly distribution?: IDashboardDistributionResponse;
  readonly activity?: IDashboardActivityResponse;
  readonly details?: IDashboardAreaDetailsModel<IDashboardDetailsResponse>;
}

export const dashboardReducer = createReducer(initialCommonState, builder => {
  builder.addCase(dashboardActions.dashboardSummaryFetched, (state, action) => {
    state.summary = action.payload;
  });

  builder.addCase(dashboardActions.dashboardStateCleanup, () => {
    return initialCommonState;
  });

  builder.addCase(dashboardActions.dashboardDistributionFetched, (state, action) => {
    state.distribution = action.payload;
  });

  builder.addCase(dashboardActions.dashboardActivityFetched, (state, action) => {
    state.activity = action.payload;
  });

  builder.addCase(dashboardActions.dashboardDetailsFetched, (state, action) => {
    state.details = action.payload;
  });
});
