import { createAction } from '@reduxjs/toolkit';

import { IFilterCreateModel } from '../../domain/models/filterCreateModel';
import { IFilterDeleteModel } from '../../domain/models/filterDeleteModel';
import { IFilterUpdateModel } from '../../domain/models/filterUpdateModel';
import { IAreaResponse } from '../../services/responseModels/areaResponse';
import { IFiltersResponse } from '../../services/responseModels/filterResponse';
import { IMagnetometerColorSchemeResponse } from '../../services/responseModels/magnetometerColorSchemeResponse';

const areasRequested = createAction('AREAS_REQUESTED');
const areasFetched = createAction<IAreaResponse[]>('AREAS_FETCHED');

const filtersFetch = createAction<string>('FILTERS_FETCH');
const filtersFetched = createAction<IFiltersResponse[]>('FILTERS_FETCHED');
const filterCreate = createAction<IFilterCreateModel>('FILTER_CREATE');
const filterUpdate = createAction<IFilterUpdateModel>('FILTER_UPDATE');
const filterSaved = createAction<IFiltersResponse>('FILTER_SAVED');
const filterDelete = createAction<IFilterDeleteModel>('FILTER_DELETE');
const filterDeleted = createAction<number>('FILTER_DELETED');

const magnetometerColorSchemeRequested = createAction('MAGNETOMETER_COLOR_SCHEME_REQUESTED');
const magnetometerColorSchemeFetched = createAction<IMagnetometerColorSchemeResponse[]>('MAGNETOMETER_COLOR_SCHEME_FETCHED');

export const contactsOverviewActions = {
  areasRequested,
  areasFetched,
  filtersFetch,
  filtersFetched,
  filterCreate,
  filterUpdate,
  filterSaved,
  filterDelete,
  filterDeleted,
  magnetometerColorSchemeRequested,
  magnetometerColorSchemeFetched
};
