import { useOidc } from '@axa-fr/react-oidc';
import { FC, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { urlHelper } from '../../utils/urlHelper';

const BackToRootLinkComponent: FC<BackToRootLinkComponentProps> = (props: BackToRootLinkComponentProps) => {
  const { isAuthenticated } = useOidc();
  const to = isAuthenticated ? urlHelper.routes.contacts : urlHelper.routes.root;

  return <Link to={to} {...props} />;
};

export default BackToRootLinkComponent;

//'to' property will be calculated, so omitting it here /Renat
export type BackToRootLinkComponentProps = Omit<LinkProps & RefAttributes<HTMLAnchorElement>, 'to'>;
