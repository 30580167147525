import { useOidc } from '@axa-fr/react-oidc';
import { css } from '@emotion/react';
import ExitIcon from '@rsuite/icons/Exit';
import UserIcon from '@rsuite/icons/legacy/User';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GrHomeRounded } from 'react-icons/gr';
import { GrUserAdmin } from 'react-icons/gr';
import { MdDashboard } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Header, Nav, Navbar } from 'rsuite';

import { Language } from '../../domain/enums/language';
import { UserRole } from '../../domain/enums/userRole';
import { useUserRoles } from '../../hooks/useUserRoles';
import { primaryBackgroundColor, secondaryHeaderButtonStyle } from '../../sharedStyles';
import { constants } from '../../utils/constants';
import { urlHelper } from '../../utils/urlHelper';
import ProtectedComponent from '../authorization/protectedComponent';
import BackToRootLinkComponent from '../core/backToRootLinkComponent';

import HeaderButtonsRibbonComponent from './headerButtonsRibbonComponent';

const primaryHeaderStyle = css({
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
});

const logoWrapper = css({
  display: 'flex',
  flex: '0 1 200px',
  justifyContent: 'center',
  position: 'absolute',
  top: '-28px'
});

const logoStyle = css({
  width: '37px',
  height: '65px'
});

const languageBlock = css({
  position: 'absolute',
  left: '100px'
});

const languageStyle = css({
  fontStyle: 'italic'
});

const avatarContainerStyle = css({
  position: 'absolute',
  right: '50px',
  zIndex: 8
});

const avatarIconStyle = css({
  '& > .rs-dropdown-toggle.rs-dropdown-toggle-no-caret > .rs-dropdown-toggle-icon.rs-icon': {
    marginRight: 0
  }
});

const secondaryHeaderStyle = css([primaryBackgroundColor], {
  padding: '0 40px',
  height: '44px'
});

const homeTitleStyle = css({ marginTop: '4px', color: 'rgb(61, 56, 56)' });

const itemStyle = css({
  '&.rs-dropdown-item.rs-dropdown-item-focus': {
    color: 'black',
    backgroundColor: '#e5e5ea'
  },
  '&>.rs-dropdown-item-menu-icon': {
    marginRight: '10px'
  }
});

const noMarginStyle = css({
  '& .rs-navbar-item-icon': {
    margin: 0
  }
});

const rolesStyle = css({ padding: '10px 10px 0 10px', width: '140px' });

const HeaderComponent: FC = () => {
  const { isAuthenticated, logout } = useOidc();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const roles = useUserRoles();

  const onSignOutClick = useCallback(() => {
    logout(`${window.location.origin}${urlHelper.patterns.signedOut}`);
  }, [logout]);

  const onAdminClick = useCallback(() => {
    navigate(urlHelper.routes.admin);
  }, [navigate]);

  const changeLanguage = useCallback(
    (language: Language) => {
      //We can use useLocalStorage hook here, but it serializes values before storing them /Renat
      localStorage.setItem(constants.localizationKey, language);
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  const toDutch = useCallback(() => {
    changeLanguage(Language.Dutch);
  }, [changeLanguage]);

  const toEnglish = useCallback(() => {
    changeLanguage(Language.English);
  }, [changeLanguage]);

  const renderLanguageSelection = () => {
    return (
      <div css={languageBlock}>
        <Button appearance="link" onClick={toDutch} css={languageStyle}>
          {t('Dutch')}
        </Button>
        <Button appearance="link" onClick={toEnglish} css={languageStyle}>
          {t('English')}
        </Button>
      </div>
    );
  };

  const tryRenderAvatar = () => {
    if (isAuthenticated) {
      return (
        <div css={avatarContainerStyle}>
          <Dropdown css={avatarIconStyle} icon={<UserIcon />} noCaret={true} trigger="hover" placement="bottomEnd">
            <Dropdown.Item panel={true} css={rolesStyle}>
              <b>{t('Roles')}:</b>
              <ul>
                {roles.map(role => (
                  <li key={role}>{role}</li>
                ))}
              </ul>
            </Dropdown.Item>
            <Dropdown.Separator />
            <ProtectedComponent allowedRoles={[UserRole.DataAdmin, UserRole.AppAdmin]}>
              <Dropdown.Item css={itemStyle} icon={<GrUserAdmin />} onClick={onAdminClick}>
                {t('Admin page')}
              </Dropdown.Item>
            </ProtectedComponent>
            <Dropdown.Item css={itemStyle} icon={<ExitIcon />} onClick={onSignOutClick}>
              {t('Sign out')}
            </Dropdown.Item>
          </Dropdown>
        </div>
      );
    }
  };

  const tryRenderDashboardButton = () => {
    if (isAuthenticated) {
      return (
        <Nav.Item
          icon={<MdDashboard size={15} />}
          css={[secondaryHeaderButtonStyle, noMarginStyle]}
          as={Link}
          title={t('Dashboard')}
          to={urlHelper.routes.dashboard}
        />
      );
    }
  };

  return (
    <Header>
      <div css={primaryHeaderStyle}>
        <div css={logoWrapper}>
          <img alt="logo" css={logoStyle} src="/logo.svg" />
        </div>
        {renderLanguageSelection()}
        {tryRenderAvatar()}
      </div>
      <Navbar css={secondaryHeaderStyle}>
        <Nav>
          <Nav.Item icon={<GrHomeRounded size={15} />} css={secondaryHeaderButtonStyle} as={BackToRootLinkComponent}>
            <b css={homeTitleStyle}>{t('Landelijk Opslagsysteem Onderwater Objecten')}</b>
          </Nav.Item>
          {tryRenderDashboardButton()}
        </Nav>
        <HeaderButtonsRibbonComponent />
      </Navbar>
    </Header>
  );
};

export default HeaderComponent;
