import { IFilterCreateModel } from '../domain/models/filterCreateModel';
import { IFilterDeleteModel } from '../domain/models/filterDeleteModel';
import { IFilterUpdateModel } from '../domain/models/filterUpdateModel';
import { createApiClient } from '../utils/httpClient';

import { IFilterCreateRequest } from './requestModels/filterCreateRequest';
import { IFilterUpdateRequest } from './requestModels/filterUpdateRequest';
import { IFiltersResponse } from './responseModels/filterResponse';

export async function getFilters(userId: string): Promise<IFiltersResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Users/${userId}/Filters`;

  const response = await apiClient.get<IFiltersResponse[]>(url);

  return response.data;
}

export async function createFilter(model: IFilterCreateModel): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/Users/${model.userId}/Filters`;

  const response = await apiClient.post<number, IFilterCreateRequest>(url, model.request);

  return response.data;
}

export async function updateFilter(model: IFilterUpdateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Users/${model.userId}/Filters/${model.id}`;

  await apiClient.put<number, IFilterUpdateRequest>(url, model.request);
}

export async function deleteFilter(model: IFilterDeleteModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Users/${model.userId}/Filters/${model.id}`;

  await apiClient.delete(url);
}
