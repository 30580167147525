import { AuxiliaryPageType } from '../domain/enums/auxiliaryPageType';
import { IAuxiliaryPageEnabledSwitchModel } from '../domain/models/auxiliaryPageEnabledSwitchModel';
import { IAuxiliaryPageUpdateContentModel } from '../domain/models/auxiliaryPageUpdateContentModel';
import { createApiClient } from '../utils/httpClient';

import { IAuxiliaryPageResponse } from './responseModels/auxiliaryPageResponse';
import { IAuxiliaryPageStatusResponse } from './responseModels/auxiliaryPageStatusResponse';

export async function getAuxiliaryPages(): Promise<IAuxiliaryPageStatusResponse[]> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IAuxiliaryPageStatusResponse[]>('api/AuxiliaryPages');

  return response.data;
}

export async function getAuxiliaryPage(type: AuxiliaryPageType): Promise<IAuxiliaryPageResponse> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IAuxiliaryPageResponse>(`api/AuxiliaryPages/${type}`);

  return response.data;
}

export async function switchAuxiliaryPageEnabled(model: IAuxiliaryPageEnabledSwitchModel): Promise<void> {
  const apiClient = createApiClient();

  await apiClient.patch(`api/AuxiliaryPages/${model.type}`, model.request);
}

export async function updateAuxiliaryPageContent(model: IAuxiliaryPageUpdateContentModel): Promise<void> {
  const apiClient = createApiClient();

  await apiClient.post(`api/AuxiliaryPages/${model.type}`, model.request);
}
