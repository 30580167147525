const root = '/';
const info = '/info';
const contacts = '/contacts';
const details = '/details';
const auxiliaryInfo = '/page';
const signedOut = '/signed-out';
const admin = '/admin';
const notAuthorized = '/not-authorized';
const dashboard = '/dashboard';
const wildcard = '*';

const patterns = {
  root: root,
  info: info,
  contacts: contacts,
  details: `${details}/:id`,
  auxiliaryInfo: `${auxiliaryInfo}/:page`,
  signedOut: signedOut,
  admin: admin,
  notAuthorized: notAuthorized,
  dashboard: dashboard,
  wildcard: wildcard
} as const;

const routes = {
  root: root,
  info: info,
  contacts: contacts,
  details: (id: number) => `${details}/${id}`,
  auxiliaryInfo: (page: string) => `${auxiliaryInfo}/${page}`,
  signedOut: signedOut,
  admin: admin,
  notAuthorized: notAuthorized,
  dashboard: dashboard,
  wildcard: wildcard
} as const;

//This will force to have the same structure for routes and patterns /Renat
type Patterns = typeof patterns;
type Routes = typeof routes;

type RoutesType = {
  [P in keyof Patterns]: Routes[P];
};

type PatternsType = {
  [P in keyof Routes]: Patterns[P];
};

type UrlHelper = {
  patterns: PatternsType;
  routes: RoutesType;
  buildUrl: <T extends object>(path: string, queryParams: T, ...excludes: (keyof T)[]) => string;
};

function buildUrl<T extends object>(path: string, object: T, ...excludes: (keyof T)[]): string {
  const queryString = Object.entries(object)
    .filter(([key, value]) => !excludes.includes(key as keyof T) && !!value)
    .reduce<string>(
      (prev, [key, value], index) =>
        prev + `${index === 0 ? '' : '&'}${Array.isArray(value) ? value.map(c => `${key}=${c}`).join('&') : `${key}=${value}`}`,
      ''
    );

  return queryString ? `${path}?${queryString}` : path;
}

export const urlHelper: UrlHelper = {
  patterns,
  routes,
  buildUrl
};

//Such types can't be coupled with patterns or routes so keeping them synchronized manually /Renat
export type DetailsParams = { id: string };
export type AuxiliaryInfoParams = { page: string };
