import { serialize } from 'object-to-formdata';

import { IDownloadFileCreateModel } from '../domain/models/downloadFileCreateModel';
import { IDownloadFileDeleteModel } from '../domain/models/downloadFileDeleteModel';
import { IDownloadFilePatchModel } from '../domain/models/downloadFilePatchModel';
import { IDownloadFilesDeleteModel } from '../domain/models/downloadFilesDeleteModel';
import { IDownloadFileUpdateModel } from '../domain/models/downloadFileUpdateModel';
import { createApiClient } from '../utils/httpClient';

import { IDownloadFileCreateRequest } from './requestModels/downloadFileCreateRequest';
import { IDownloadFileResponse } from './responseModels/downloadFileResponse';

export async function getDownloadFiles(): Promise<IDownloadFileResponse[]> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles`;

  const response = await apiClient.get<IDownloadFileResponse[]>(url);

  return response.data;
}

export async function createDownloadFile(model: IDownloadFileCreateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles`;

  const request: IDownloadFileCreateRequest = {
    name: model.name,
    author: model.author,
    file: model.file.blobFile as File
  };
  const formData = serialize(request, { dotsForObjectNotation: true, noFilesWithArrayNotation: true });

  await apiClient.post(url, formData);
}

export async function patchDownloadFile(model: IDownloadFilePatchModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles/${model.id}/${model.version}`;

  await apiClient.patch(url, model.request);
}

export async function updateDownloadFile(model: IDownloadFileUpdateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles/${model.id}/${model.version}`;

  const formData = serialize(model.request, { dotsForObjectNotation: true, noFilesWithArrayNotation: true });

  await apiClient.put(url, formData);
}

export async function deleteDownloadFile(model: IDownloadFileDeleteModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles/${model.id}/${model.version}`;

  await apiClient.delete(url);
}

export async function deleteDownloadFiles(model: IDownloadFilesDeleteModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/DownloadFiles/${model.id}`;

  await apiClient.delete(url);
}
