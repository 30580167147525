import { OidcUserInfo } from '@axa-fr/oidc-client';
import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { css } from '@emotion/react';
import { useLocalStorage, useVisibilityChange } from '@uidotdev/usehooks';
import { differenceInMinutes } from 'date-fns';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Content } from 'rsuite';

import LoaderComponent from '../components/core/loaderComponent';
import FooterComponent from '../components/footerComponent';
import HeaderComponent from '../components/header/headerComponent';
import RoutingComponent from '../components/routingComponent';
import { IUserSyncModel } from '../domain/models/userSyncModel';
import { IUserTrackModel } from '../domain/models/userTrackModel';
import { contentHeight } from '../sharedStyles';
import { commonActions } from '../store/actions/commonActions';
import { AppState } from '../store/appReducer';
import { constants } from '../utils/constants';
import { environment } from '../utils/environment';

const mainBlockStyle = css({
  width: '100%',
  height: '100%'
});

const contentStyle = css(contentHeight, {
  display: 'flex'
});

const tokenDelayInMs = environment.tokenDelayInMinutes * 60 * 1000;

function shouldCurrentUserBeSynced(currentUser: OidcUserInfo | undefined, lastSyncedUser: IUserSyncModel | null): boolean {
  if (!currentUser) {
    return false;
  }

  if (!lastSyncedUser) {
    return true;
  }

  return (
    currentUser.sub !== lastSyncedUser.userId ||
    currentUser.email !== lastSyncedUser.request.email ||
    currentUser.given_name !== lastSyncedUser.request.firstName ||
    currentUser.family_name !== lastSyncedUser.request.lastName
  );
}

function shouldCurrentUserBeTracked(
  currentUser: OidcUserInfo | undefined,
  lastSyncedUser: IUserSyncModel | null,
  lastTrackedUser: IUserTrackModel | null
): boolean {
  if (!currentUser || !lastSyncedUser) {
    return false;
  }

  if (!lastTrackedUser) {
    return true;
  }

  if (currentUser.sub !== lastTrackedUser.userId) {
    return true;
  }

  return differenceInMinutes(new Date(), lastTrackedUser.date) >= 30;
}

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useOidc();
  const { oidcUser } = useOidcUser();
  const projectionsLoaded = useSelector<AppState, boolean>(p => p.commonState.projectionsLoaded);
  const [lastSyncedUser] = useLocalStorage<IUserSyncModel | null>(constants.lastSyncedUserKey, null);
  const [lastTrackedUser] = useLocalStorage<IUserTrackModel | null>(constants.lastTrackedUserKey, null);
  const documentVisible = useVisibilityChange();

  useEffect(
    function requestProjections() {
      dispatch(commonActions.requestProjections());
    },
    [dispatch]
  );

  useEffect(
    function requestToken() {
      let intervalId: NodeJS.Timeout | undefined = undefined;

      if (isAuthenticated) {
        const dispatchTokenRequested = () => dispatch(commonActions.tokenRequested(window.location.origin));

        //Requests token once and then schedules an interval /Renat
        dispatchTokenRequested();
        intervalId = setInterval(dispatchTokenRequested, tokenDelayInMs);
      }

      return () => clearInterval(intervalId);
    },
    [dispatch, isAuthenticated]
  );

  useEffect(
    function syncUser() {
      if (isAuthenticated && shouldCurrentUserBeSynced(oidcUser, lastSyncedUser)) {
        const request: IUserSyncModel = {
          userId: oidcUser.sub,
          request: {
            email: oidcUser.email || '',
            firstName: oidcUser.given_name || '',
            lastName: oidcUser.family_name || ''
          }
        };

        dispatch(commonActions.syncUser(request));
      }
    },
    [dispatch, isAuthenticated, lastSyncedUser, oidcUser]
  );

  useEffect(
    function trackUser() {
      if (isAuthenticated && documentVisible && shouldCurrentUserBeTracked(oidcUser, lastSyncedUser, lastTrackedUser)) {
        dispatch(commonActions.trackUser(oidcUser.sub));
      }
    },
    [dispatch, documentVisible, isAuthenticated, lastSyncedUser, lastTrackedUser, oidcUser]
  );

  if (projectionsLoaded) {
    return (
      <Container css={mainBlockStyle}>
        <HeaderComponent />
        <Content css={contentStyle}>
          <RoutingComponent />
        </Content>
        <FooterComponent />
      </Container>
    );
  } else {
    return <LoaderComponent />;
  }
};

export default AppContainer;
