import { createAction } from '@reduxjs/toolkit';

import { IDownloadFileCreateModel } from '../../domain/models/downloadFileCreateModel';
import { IDownloadFileDeleteModel } from '../../domain/models/downloadFileDeleteModel';
import { IDownloadFilePatchModel } from '../../domain/models/downloadFilePatchModel';
import { IDownloadFilesDeleteModel } from '../../domain/models/downloadFilesDeleteModel';
import { IDownloadFileUpdateModel } from '../../domain/models/downloadFileUpdateModel';
import { IRwsWizardState } from '../../domain/models/rwsWizardState';
import { IUserSyncModel } from '../../domain/models/userSyncModel';
import { IFindInRadiusRequest } from '../../services/requestModels/findInRadiusRequest';
import { IGisExportRequest } from '../../services/requestModels/gisExportRequest';
import { IWordExportRequest } from '../../services/requestModels/wordExportRequest';
import { IDownloadFileResponse } from '../../services/responseModels/downloadFileResponse';
import { INationalContactInRadiusResponse } from '../../services/responseModels/nationalContactInRadiusResponse';
import { IReferenceResponse } from '../../services/responseModels/referenceResponse';
import { ITokenResponse } from '../../services/responseModels/tokenResponse';

const requestProjections = createAction('PROJECTIONS_REQUESTED');
const projectionsLoaded = createAction('PROJECTIONS_LOADED');

const syncUser = createAction<IUserSyncModel>('SYNC_USER');
const trackUser = createAction<string>('TRACK_USER');

const gisExportStarted = createAction<IGisExportRequest>('GIS_EXPORT_STARTED');
const wordExportStarted = createAction<IWordExportRequest>('WORD_EXPORT_STARTED');
const activeExportCancelled = createAction('ACTIVE_EXPORT_CANCELLED');

const exportStateChanged = createAction<boolean>('EXPORT_STATE_CHANGED');

const tokenRequested = createAction<string>('TOKEN_REQUESTED');
const tokenRetrieved = createAction<ITokenResponse>('TOKEN_RETRIEVED');

const contactsInRadiusRequested = createAction<IFindInRadiusRequest>('CONTACTS_IN_RADIUS_REQUESTED');
const contactsInRadiusRetrieved = createAction<INationalContactInRadiusResponse[]>('CONTACTS_IN_RADIUS_RETRIEVED');

const completeRwsWizard = createAction<IRwsWizardState>('COMPLETE_RWS_WIZARD');
const saveInProgressChanged = createAction<boolean>('SAVE_IN_PROGRESS_CHANGED');

const referencesRequested = createAction('REFERENCES_REQUESTED');
const referencesFetched = createAction<IReferenceResponse[]>('REFERENCES_FETCHED');

const archiveNationalContacts = createAction<number[]>('CONTACTS_ARCHIVAL_SWITCHED');
const deleteNationalContacts = createAction<number[]>('CONTACTS_DELETION_SWITCHED');

const downloadFilesRequested = createAction('DOWNLOAD_FILES_REQUESTED');
const downloadFilesReset = createAction('DOWNLOAD_FILES_RESET');
const downloadFilesFetched = createAction<IDownloadFileResponse[]>('DOWNLOAD_FILES_FETCHED');
const downloadFileCreate = createAction<IDownloadFileCreateModel>('DOWNLOAD_FILE_CREATE');
const downloadFilePatch = createAction<IDownloadFilePatchModel>('DOWNLOAD_FILE_PATCH');
const downloadFileUpdate = createAction<IDownloadFileUpdateModel>('DOWNLOAD_FILE_UPDATE');
const downloadFileDelete = createAction<IDownloadFileDeleteModel>('DOWNLOAD_FILE_DELETE');
const downloadFilesDelete = createAction<IDownloadFilesDeleteModel>('DOWNLOAD_FILES_DELETE');

export const commonActions = {
  requestProjections,
  projectionsLoaded,
  exportStateChanged,
  gisExportStarted,
  wordExportStarted,
  tokenRequested,
  tokenRetrieved,
  activeExportCancelled,
  contactsInRadiusRequested,
  contactsInRadiusRetrieved,
  completeRwsWizard,
  saveInProgressChanged,
  referencesRequested,
  referencesFetched,
  archiveNationalContacts,
  deleteNationalContacts,
  syncUser,
  trackUser,
  downloadFilesRequested,
  downloadFilesReset,
  downloadFilesFetched,
  downloadFileCreate,
  downloadFilePatch,
  downloadFileUpdate,
  downloadFileDelete,
  downloadFilesDelete
};
