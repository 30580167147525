import { IUserSyncModel } from '../domain/models/userSyncModel';
import { createApiClient } from '../utils/httpClient';

export async function syncUser(model: IUserSyncModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Users/${model.userId}/Sync`;

  await apiClient.post(url, model.request);
}

export async function trackUser(userId: string): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Users/${userId}/Track`;

  await apiClient.post(url);
}
